@import "global";

.reference {
  @include padding(130px 0);
  &--half-spacing {
    @include padding(60px 0 130px);
  }
  &--no-padding-bottom {
    padding-bottom: 0;
  }
  .column {
    width: 100%;
    @include flex-grid-column(10, false);
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  &__quote {
    @extend %typo-b6;
    p {
      display: inline;
    }
    
  }
  &__perex {
    color: $cGray-400;
    margin-top: 20px;
  }
  &__author {
    display: flex;
    margin-top: 30px;
    margin-bottom: 15px;
    color: $cGray-200;
  }
  &__link {
    color: $cPrimary;
    font-weight: $fwBold;
  }
  @include breakpoint(phone down) {
    @include padding(70px 0 60px);
   .column{
      @include flex-grid-column(12, false);
    }
    &__author {
      margin-top: 20px;
    }
  }
}
